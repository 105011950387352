import Users from "../../components/general/Users";
import Buildings from "../../components/general/Buildings";
import TotalUtilityUsage from "../../components/utilities/TotalUtilityUsage";
import Utility from "../../components/utilities/Utility";
import {
    ELECTRICITY_UTILITY_LABEL,
    ELECTRICITY_UTILITY_ID,
    WATER_UTILITY_ID,
    WATER_UTILITY_LABEL, GAS_UTILITY_ID, GAS_UTILITY_LABEL
} from "../constants/utility.constants";

export const SIDE_NAVIGATION = [
    { constant: "general",
      label: "General",
      sub_nav: [
         { constant: "buildings", label: "Buildings", icon: "bi bi-building", component: Buildings},
         { constant: "users", label: "Users", icon: "bi bi-person", component: Users},
      ]},

    { constant: "utilities",
      label: "Utilities",
      constraint: true,
      sub_nav: [
         { constant: "total_utility_usage", label: "Total Utility Usage", icon: "bi bi-pie-chart", component: TotalUtilityUsage},
         { constant: "water", label: "Water", icon: "bi bi-water", component: Utility, props: {utility_id: WATER_UTILITY_ID, utility_name: WATER_UTILITY_LABEL} },
         { constant: "electricity", label: "Electricity", icon: "bi bi-lightning-charge", component: Utility, props: {utility_id: ELECTRICITY_UTILITY_ID, utility_name: ELECTRICITY_UTILITY_LABEL} },
         { constant: "gas", label: "Gas", icon: "bi bi-fire", component: Utility, props: {utility_id: GAS_UTILITY_ID, utility_name: GAS_UTILITY_LABEL} }
      ]},
];