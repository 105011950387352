import {configureStore} from "@reduxjs/toolkit";

import modalSlice from "./utils/slices/modal.slice";
import toastSlice from "./utils/slices/toasts.slice";
import navigationSlice from "./utils/slices/navigation.slice";
import buildingSlice from "./utils/slices/building.slice";

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    reducer: {
        modal: modalSlice,
        toasts: toastSlice,
        building: buildingSlice,
        navigation: navigationSlice
    },
})
