import React from 'react';

const TextInput = (props) => {
    return (
        <div>
            {props.label && <label htmlFor={props.name} className={`lead form-label fs-16 p-0 m-0 mb-1 ${props.labelClassName} `}>{props.label}</label> }
            <input
                ref={props.ref}
                id={props.id || props.name}
                type={props.type || "text"}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
                disabled={props.disabled}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                onChange={props.onChange}
                onClick={props.onClick}
                onMouseDown={props.onMouseDown}
                onMouseLeave={props.onMouseLeave}
                onMouseOut={props.onMouseOut}
                onKeyDown={props.onKeyDown}
                onKeyUp={props.onKeyUp}
                autoFocus={props.autoFocus}
                className={`form-control text-secondary-emphasis fs-14 ${props.className}`}
                style={{
                    ...props.style,
                    border: `${(props.error || props.errorBorder) && !props.successBorder ? '1px solid red' : ''}`,
                }}
            />
            {props.error && <div className={`lead text-danger fs-6 mt-1 mb-2 ${props.errorClassName} `}>{props.error}</div> }
        </div>
    );
};

export default TextInput;
