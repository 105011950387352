import {createSlice} from "@reduxjs/toolkit";

const building = createSlice({
    name: "building",
    initialState: {},
    reducers: {
        setBuilding: (state, {payload}) => {
            return {
                ...state,
                ...payload
            };
        },
    }
})

export const {setBuilding} = building.actions;
export default building.reducer;