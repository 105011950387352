export const PIE_CHART_TIMEFRAMES = [
    { constant: "today",
      label: "Today"},

    { constant: "week",
      label: "Week"},

    { constant: "month",
      label: "Month"},

    { constant: 'year',
      label: "Year"}
];

export const BAR_CHART_TIMEFRAMES = [
    { constant: "today",
      label: "Today"},

    { constant: "weekly",
      label: "Weekly"},

    { constant: "monthly",
      label: "Monthly"},

    { constant: "yearly",
      label: "Yearly"}
];

export const ALL_MONTHS = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];