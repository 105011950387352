import React, {useEffect, useState} from 'react';
import TextInput from "../form/TextInput";
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {postUnauthenticated} from "../../utils/actions/post.actions";
import {isEmpty} from "../../utils/helpers/app.helpers";

const BuildingCRUD = (props) => {
    const [init, setInit] = useState(false);
    const [errors, setErrors] = useState(false);
    const [building, setBuilding] = useState({});

    useEffect(() => {
        if(props.building.id) {
            setBuilding(props.building);
        }else{
            getDefaultBuilding();
        }
        setInit(true);
    }, [props.building.id]);

    function getDefaultBuilding() {
        let url = API_ENDPOINT + "v1/Buildings/getDefaultBuilding";
        let params = {}

        postUnauthenticated(url, params).then(results => {
            setBuilding(results.data);
        });
    }

    function createBuilding() {
        let url = API_ENDPOINT + "v1/Buildings/createBuilding";
        let params = {
            building: building
        }

        postUnauthenticated(url, params).then(results => {
            props.onClose();
        });
    }

    function updateBuilding() {
        let url = API_ENDPOINT + "v1/Buildings/updateBuilding";
        let params = {
            building: building
        }

        postUnauthenticated(url, params).then(results => {
            props.onClose();
        });
    }

    function validateForm() {
        let _errors = {};

        if(isEmpty(building.label)) _errors['label'] = "Field cannot be empty";
        if(isEmpty(building.name)) _errors['name'] = "Field cannot be empty";

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            props.building.id ? updateBuilding() : createBuilding();
        }
    }

    function handleChange(event) {
        setBuilding({...building, [event.target.name]: event.target.value});
    }

    return (
        init &&
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-70 h-70 m-auto">
                    <div className="modal-header p-3">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Create a building</h3>
                        <button onClick={() => props.onClose()}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <i className="bi bi-x-lg text-secondary-emphasis" />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="modal-body w-95 m-auto" style={{overflowY: "scroll"}}>
                        <div className="d-flex">
                            <div className="mb-3 col-2 me-2">
                                <TextInput
                                    label="Label:"
                                    name="label"
                                    value={building.label}
                                    onChange={(e) => handleChange(e)}
                                    error={errors.label}
                                />
                            </div>

                            <div className="mb-3 col-9 ms-3">
                                <TextInput
                                    label="Name:"
                                    name="name"
                                    value={building.name}
                                    onChange={(e) => handleChange(e)}
                                    error={errors.name}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => validateForm()}>
                                {props.building.id ? 'Update' : 'Create'}
                        </button>
                        <button className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                onClick={() => props.onClose()}>
                                Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildingCRUD;