import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import BuildingCRUD from "../modals/BuildingCRUD";
import {setModal} from "../../utils/slices/modal.slice";
import {postUnauthenticated} from "../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../utils/constants/app.constants";

const Buildings = () => {
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [buildings, setBuildings] = useState([]);

    useEffect(() => {
        getAllBuildings();
    }, []);

    function getAllBuildings() {
        let url = API_ENDPOINT + "v1/Buildings/getAllBuildings";
        let params = {};

        postUnauthenticated(url, params).then(results => {
            setBuildings(results.data);
            setInit(true);
        });
    }

    function launchBuildingCRUD(building) {
        let modalSettings = {
            config: {
                component: BuildingCRUD,
                props: {
                    building: building
                }
            },
            onClose: () => {getAllBuildings()}
        }
        dispatch(setModal(modalSettings));
    }

    return (
        init &&
        <div>
            <div className="d-flex pb-2">
                <h2 className="col-9 lead fs-18 fw-semibold">Buildings</h2>
                <div className="col-3 text-end">
                    <button className="btn btn-primary w-40" onClick={() => launchBuildingCRUD({})}>
                        <i className="bi bi-building-fill-add fs-14" /> Create
                    </button>
                </div>
            </div>

            <div className="text-secondary fs-12 mb-1">Results: {buildings.length}</div>

            {buildings.map((building, index) =>
                <div className="border border-gray-200 border-1 rounded lead fs-14 p-3 bg-white mb-3">
                    <div className="text-light-emphasis fs-12 fw-lighter">#ID{building.id}</div>
                    <div className="d-flex">
                        <div className="col-10">{building.label} - {building.name}</div>
                        <div role="button" className="col-2 text-end text-primary">

                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};

export default Buildings;
