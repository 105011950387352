import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Bar, Line} from "react-chartjs-2";
import {CategoryScale, Chart, registerables} from 'chart.js';

import {DateTime} from "luxon";

import Loader from "../common/Loader";

import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {ALL_MONTHS, BAR_CHART_TIMEFRAMES, PIE_CHART_TIMEFRAMES} from "../../utils/structures/dashboard.structures";
import {isEmpty, isObjectEmpty} from "../../utils/helpers/app.helpers";
import {postUnauthenticated} from "../../utils/actions/post.actions";

const Utility = () => {
    Chart.register(...registerables);
    Chart.register(CategoryScale);

    const building = useSelector(state => state.building);
    const navigation = useSelector(state => state.navigation);

    const [init, setInit] = useState(false);
    const [utilityData, setUtilityData] = useState({metric: "", total_usage: []});
    const [linechart, setLinechart] = useState({});
    const [barchart, setBarchart] = useState({});
    const [timeframe, setTimeframe] = useState(BAR_CHART_TIMEFRAMES[0]);
    const [subTimeframe, setSubTimeframe] = useState(null);

    useEffect(() => {
        getFilteredUtilityData();
    }, [navigation.side_navigation.props.utility_id]);

    useEffect(() => {
        getFilteredUtilityData();
    }, [subTimeframe, building]);

    useEffect(() => {
        handleTimeframeSelection();
    }, [timeframe]);

    useEffect(() => {
        timeframe.constant === "today" ? configureLinechart() : configureBarchart();
        setInit(true);
    }, [utilityData]);

    function getFilteredUtilityData() {
        let url = API_ENDPOINT + "v1/BuildingUtilities/getFilteredUtilityData";
        let params = {
            building_id: building.id,
            utility_id: navigation.side_navigation.props.utility_id,
            timeframe: timeframe.constant,
            sub_timeframe: subTimeframe
        }

        postUnauthenticated(url, params).then(results => {
            setUtilityData(results.data);
        });
    }

    function handleTimeframeSelection() {
        if(timeframe.constant === "today") {
            setSubTimeframe(null);
        }
        if(timeframe.constant === "weekly") {
            setSubTimeframe(1);
        }
        if(timeframe.constant === "monthly") {
            setSubTimeframe(DateTime.local().month-1);
        }
        if(timeframe.constant === "yearly") {
            setSubTimeframe(DateTime.local().year)
        }
    }

    function configureLinechart() {
        let _labels = timeframe.constant === "today" ? ['0-6hrs', '6-12hrs', '12-18hrs', '18-24hrs'] : null;

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display : true,
                    text: navigation.side_navigation.props.utility_name
                }
            }
        };

        const data = {
            type: "line",
            labels: _labels,
            datasets: [{
                label: utilityData.metric,
                data: utilityData.total_usage
            }]
        };

        setLinechart({
            data: data,
            options: options
        })
    }

    function configureBarchart() {
        let labels = timeframe.constant === "weekly" ? ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']
            : timeframe.constant === "monthly" ? ['Week 1', 'Week 2', 'Week 3', 'Week 4']
                : timeframe.constant === "yearly" ? ['Q1', 'Q2', 'Q3', 'Q4']
                    : null

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display : true,
                    text: navigation.side_navigation.props.utility_name
                }
            }
        }

        const data = {
            type: "bar",
            labels: labels,
            datasets: [{
                label: utilityData.metric,
                data: Object.assign([], utilityData.total_usage)
            }]
        };

        setBarchart({
            data: data,
            options: options
        });
    }

    return (
        <div className="">
            <div className="d-flex pb-2">
                <h2 className="col-9 lead fs-18 fw-semibold align-self-center">{navigation.side_navigation.props.utility_name}</h2>
                <div className="col-3 text-end">
                    <button className="btn btn-primary w-50" onClick={() => null}>
                        <i className={`bi bi-plus-lg fs-16`} /> Insert data
                    </button>
                </div>
            </div>

            <div className="">
                <div className="d-flex mt-3 bg-white">
                    {BAR_CHART_TIMEFRAMES.map((tf, index) =>
                        <div key={index} role="button" onClick={() => setTimeframe(tf)}
                             className={`col-3 text-center p-1 ${timeframe.constant === tf.constant ? 'bg-green-300' : ''} border border-gray-300`}>
                            <span className="lead text-center rounded fs-16">{tf.label}</span>
                        </div>
                    )}
                </div>

                {init ?
                    <div>
                        {timeframe.constant === "weekly" &&
                            <div className="justify-center d-flex w-full items-center border border-t-1 border-b-1 border-t-gray-200 border-b-gray-200 bg-white">
                                <div className="col-4 text-end">
                                    {subTimeframe != 1 &&
                                        <button onClick={() => setSubTimeframe(subTimeframe - 1)}>
                                            <span className="mr-3 pt-2 pb-2">
                                                <i className="bi bi-arrow-left" />
                                            </span>
                                        </button>
                                    }
                                </div>

                                <div className="col-4 pt-2 pb-2 text-center text-secondary-emphasis lead fs-16">Week {subTimeframe} - {DateTime.local().toFormat('MMMM')}</div>

                                <div className="col-4 text-start">
                                    {subTimeframe != 4 &&
                                        <button onClick={() => setSubTimeframe(subTimeframe + 1)}>
                                            <span className="mr-3 pt-2 pb-2">
                                                <i className="bi bi-arrow-right" />
                                            </span>
                                        </button>
                                    }
                                </div>
                            </div>
                        }

                        {timeframe.constant === "monthly" &&
                            <div className="justify-center d-flex w-full items-center border border-t-1 border-b-1 border-t-gray-200 border-b-gray-200 bg-white">
                                <div className="col-4 text-end">
                                    {subTimeframe !== 0 &&
                                        <button onClick={() => setSubTimeframe(subTimeframe-1)}>
                                            <span className="mr-6 pt-2 pb-2">
                                                <i className="bi bi-arrow-left" />
                                            </span>
                                        </button>
                                    }
                                </div>

                                <div className="col-4 pt-2 pb-2 text-center text-secondary-emphasis lead fs-16">{ALL_MONTHS[subTimeframe]}</div>

                                <div className="col-4 text-start">
                                    {subTimeframe !== DateTime.local().month-1 &&
                                        <button onClick={() => setSubTimeframe(subTimeframe+1)}>
                                            <span className="ml-6 pt-2 pb-2">
                                                <i className="bi bi-arrow-right" />
                                            </span>
                                        </button>
                                    }
                                </div>
                            </div>
                        }

                        {timeframe.constant === "yearly" &&
                            <div className="justify-center d-flex w-full items-center border border-t-1 border-b-1 border-t-gray-200 border-b-gray-200 bg-white">
                                <div className="col-4 text-end">
                                    <button onClick={() => setSubTimeframe(subTimeframe-1)}>
                                        <span className="mr-6 pt-2 pb-2 text-right">
                                            <i className="bi bi-arrow-left" />
                                        </span>
                                    </button>
                                </div>

                                <div className="col-4 pt-2 pb-2 text-center text-secondary-emphasis lead fs-16">{subTimeframe}</div>

                                <div className="col-4 text-start">
                                    {subTimeframe != DateTime.local().year &&
                                        <button onClick={() => setSubTimeframe(subTimeframe+1)}>
                                            <span className="ml-6 pt-2 pb-2">
                                                <i className="bi bi-arrow-right" />
                                            </span>
                                        </button>
                                    }
                                </div>
                            </div>
                        }


                        <div className="w-100 m-auto bg-white py-3">
                            {utilityData != null && !isObjectEmpty(utilityData.total_usage) && !isEmpty(utilityData.metric) ?
                                timeframe.constant === "today"
                                    ? <Line data={linechart.data} options={linechart.options} />
                                    : <Bar data={barchart.data} options={barchart.options} />
                                : <div className="pt-2 pb-2 text-center text-secondary-emphasis lead fs-16 fst-italic">No data available</div> }
                        </div>
                    </div>
                    : <Loader />}
            </div>
        </div>
    );
};

export default Utility;