// Electricity
export const ELECTRICITY_UTILITY_ID = 1;
export const ELECTRICITY_UTILITY_CONSTANT = "electricity";
export const ELECTRICITY_UTILITY_LABEL = "Electricity";

// Water
export const WATER_UTILITY_ID = 2;
export const WATER_UTILITY_CONSTANT = "water";
export const WATER_UTILITY_LABEL = "Water";

// Gas role
export const GAS_UTILITY_ID = 3;
export const GAS_UTILITY_CONSTANT = "gas";
export const GAS_UTILITY_LABEL = "Gas";