import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {SIDE_NAVIGATION} from "../../utils/structures/navigation.structures";
import {postUnauthenticated} from "../../utils/actions/post.actions";
import {setNavigation} from "../../utils/slices/navigation.slice";
import {setBuilding} from "../../utils/slices/building.slice";

const SideNavigation = () => {
    const dispatch = useDispatch();
    const building = useSelector(state => state.building);
    const navigation = useSelector(state => state.navigation);

    const [init, setInit] = useState(false);
    const [buildings, setBuildings] = useState([]);

    const [menu, setMenu] = useState(SIDE_NAVIGATION[0].sub_nav[0]);

    useEffect(() => {
        getAllBuildings();
    }, []);

    function getAllBuildings() {
        let url = API_ENDPOINT + "v1/Buildings/getAllBuildings";
        let params = {};

        postUnauthenticated(url, params).then(results => {
            setBuildings(results.data);
            setInit(true);
        });
    }

    function handleSelectBuilding(building_id) {
        let selectedBuilding = buildings.find(x => x.id == building_id);
        dispatch(setBuilding(selectedBuilding));
    }

    function handleSelectNavigation(sub_nav) {
        let nav = {
            ...navigation,
            side_navigation: sub_nav
        }
        dispatch(setNavigation(nav));
    }

    function isBuildingSelected(nav) {
        return nav.constraint ? building && building.id : true;
    }

    return (
        init &&
        <div className="w-100">
            <div className="ms-4 py-3 mt-2">
                <h1 className="lead fw-semibold fs-24">IdeallyEnergy</h1>
            </div>

            <div className="ms-4 w-85 mb-3">
                <label className="lead fs-14 text-secondary-emphasis mb-1">Select a building:</label>
                <select className="form-control fs-12 text-secondary" onChange={(e) => handleSelectBuilding(e.target.value)}>
                    <option disabled="disabled" selected="selected">...</option>
                    {buildings.map((building, index) =>
                        <option value={building.id}>{building.label} - {building.name}</option>
                    )}
                </select>
            </div>

            <ul className="list-unstyled">
                {SIDE_NAVIGATION.map((nav, index) =>
                    isBuildingSelected(nav) &&
                        <>
                            <div className="">
                                <div className="ps-4">
                                    <li className="p-1 text-secondary fs-12 fw-medium">{nav.label}</li>
                                </div>

                                {nav.sub_nav && nav.sub_nav.map((sub_nav, sub_index) =>
                                    <div
                                        role="button"
                                        className={`w-95 rounded m-auto ps-1 border border-primary border-2 border-top-0 border-start-0 border-bottom-0 border-end-0 ${navigation.side_navigation.constant === sub_nav.constant ? 'bg-primary-subtle' : ''}`}
                                        onClick={() => handleSelectNavigation(sub_nav)}
                                    >
                                        <li className={`ms-4 ps-2 fs-14 py-3 ${navigation.side_navigation.constant === sub_nav.constant ? 'text-primary' : 'text-secondary-emphasis'}`}>
                                            <i className={`${sub_nav.icon} pe-2`} /> {sub_nav.label}
                                        </li>
                                    </div>
                                )}
                            </div>
                            <hr className="p-0 m-0 mb-2 mt-2" />
                        </>
                )}
            </ul>
        </div>
    );
};

export default SideNavigation;