import React from 'react';

const Header = () => {
    return (
        <div className="bg-white p-3 m-0 w-100 border border-1 border-start-0 border-end-0 border-top-0">
            <div className="d-flex w-100 align-self-center align-middle align-items-center">
                <div className="input-group md-form form-sm form-1 pl-0">
                    <div className="input-group-prepend">
                        <span className="input-group-text purple lighten-3" id="basic-text1">
                            <i className="bi bi-search " aria-hidden="true" />
                        </span>
                    </div>
                    <input className="form-control my-0 py-1 fs-12 text-secondary-emphasis" type="text" placeholder="Search" aria-label="Search" />
                </div>
            </div>
        </div>
    );
};

export default Header;