import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Header from "./components/common/Header";
import SideNavigation from "./components/common/SideNavigation";
import {useSelector} from "react-redux";

const AppRoutes = () => {
    const navigation = useSelector(state => state.navigation);

    return (
        <BrowserRouter>
            <div className="d-flex">
                <div className="col-2 vh-100 border border-top-0 border-start-0 border-bottom-0">
                    <SideNavigation />
                </div>

                <div className="col-10 mt-1 border border-1 border-start-0 border-end-0 border-bottom-0 bg-theme">
                    <Header />
                    <div className="py-4 w-95 m-auto">
                        {navigation && navigation.side_navigation && navigation.side_navigation.constant &&
                            <navigation.side_navigation.component /> }
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default AppRoutes;