import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import {postUnauthenticated} from "../../utils/actions/post.actions";
import {WATER_UTILITY_ID} from "../../utils/constants/utility.constants";
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {PIE_CHART_TIMEFRAMES} from "../../utils/structures/dashboard.structures";
import Loader from "../common/Loader";
import {isEmpty, isObjectEmpty} from "../../utils/helpers/app.helpers";

const TotalUtilityUsage = () => {
    ChartJS.register(ArcElement, Tooltip, Legend);
    const building = useSelector(state => state.building);

    const [init, setInit] = useState(false);
    const [pie, setPie] = useState({});
    const [totalUtilityUsage, setTotalUtilityUsage] = useState({utilities: [], total_usage: []});
    const [timeframe, setTimeframe] = useState(PIE_CHART_TIMEFRAMES[0]);

    useEffect(() => {
        getTotalBuildingUtilityUsage();
    }, [building, timeframe]);

    useEffect(() => {
        if(totalUtilityUsage) {
            filterTimeframeData();
        }
    }, [totalUtilityUsage]);

    function getTotalBuildingUtilityUsage() {
        let url = API_ENDPOINT + "v1/BuildingUtilities/getTotalBuildingUtilityUsage";
        let params = {
            building_id: building.id,
            timeframe: timeframe.constant,
        }

        postUnauthenticated(url, params).then(results => {
            setTotalUtilityUsage(results.data);
        });
    }

    function filterTimeframeData() {
        let options = {
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Pie Chart'
                    }
                },
                showAllTooltips: true
            },
        }

        let config_data = {
            type: "pie",
            labels: Object.assign([], totalUtilityUsage.utilities).filter(Boolean),
            datasets: [{
                data: Object.assign([], totalUtilityUsage.total_usage).filter(Boolean),
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            }],
        }

        setPie({
            data: config_data,
            options: options
        });

        setInit(true);
    }

    return (
        <div>
            <div className="pb-2">
                <h2 className="lead fs-18 fw-semibold">Total Utility Usage</h2>

                <div className="">
                    <div className="d-flex 2-90 mt-3 bg-white">
                        {PIE_CHART_TIMEFRAMES.map((tf, index) =>
                            <div key={index} role="button" onClick={() => setTimeframe(tf)}
                                 className={`col-3 text-center p-1 ${timeframe.constant === tf.constant ? 'bg-green-300' : ''} border border-gray-300`}>
                                <span className="lead text-center rounded fs-16">{tf.label}</span>
                            </div>
                        )}
                    </div>

                    <div className="w-100 m-auto bg-white py-3">
                        {init ?
                            isObjectEmpty(totalUtilityUsage.total_usage)
                            ? <div className="pt-2 pb-2 text-center text-secondary-emphasis lead fs-16 fst-italic">No data available</div>
                            :
                                <Pie
                                    data={pie.data}
                                    options={pie.options}
                                    radius={ 150 }
                                />
                        : <Loader /> }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalUtilityUsage;