import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {postUnauthenticated} from "../../utils/actions/post.actions";
import TextInput from "../form/TextInput";
import {isEmpty} from "../../utils/helpers/app.helpers";
import {getStatusColourById} from "../../utils/helpers/status.helper";

const UserCRUD = (props) => {
    const [init, setInit] = useState(false);
    const [errors, setErrors] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        password: "",
        confirm_password: ""
    });

    useEffect(() => {
        getStatuses();
        setInit(true);
    }, []);

    useEffect(() => {
        setUser(props.user);
    }, [props.user.id]);

    function createUser() {
        let url = API_ENDPOINT + "v1/Users/createUser";
        let params = {
            first_name: user.first_name,
            last_name: user.last_name,
            email_address: user.email_address,
            password: user.password,
        };

        postUnauthenticated(url, params).then(results => {
            let success = results.success;
            if(success) {
                props.onClose();
            }else{
                setErrors({...errors, "message": results.message})
            }
        });
    }

    function updateUser() {
        let url = API_ENDPOINT + "v1/Users/updateUser";
        let params = {
            user: user
        };

        postUnauthenticated(url, params).then(results => {
            props.onClose();
        });
    }

    function getStatuses() {
        let url = API_ENDPOINT + "v1/Statuses/getStatuses";
        let params = {};

        postUnauthenticated(url, params).then(results => {
            setStatuses(results.data);
        });
    }

    function validateForm() {
        let _errors = {};

        if(isEmpty(user.first_name)) _errors['first_name'] = "Field cannot be empty";
        if(isEmpty(user.last_name)) _errors['last_name'] = "Field cannot be empty";
        if(isEmpty(user.email_address)) _errors['email_address'] = "Field cannot be empty";

        if(!props.user.id) {
            if(isEmpty(user.password)) _errors['password'] = "Field cannot be empty";
            if(isEmpty(user.confirm_password)) _errors['confirm_password'] = "Field cannot be empty";

            if(!isEmpty(user.password) && !isEmpty(user.confirm_password) && user.password != user.confirm_password) {
                _errors['password'] = "Passwords do not match";
                _errors['confirm_password'] = "Passwords do not match";
            }
        }

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            props.user.id ? updateUser() : createUser();
        }
    }

    function handleChange(event) {
        setUser({...user, [event.target.name]: event.target.value});
    }

    return (
        init &&
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-70 h-70 m-auto">
                    <div className="modal-header p-3">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Create a user</h3>
                        <button onClick={() => props.onClose()}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <i className="bi bi-x-lg text-secondary-emphasis" />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="modal-body w-95 m-auto" style={{overflowY: "scroll"}}>
                        <div className="d-flex w-100 mb-4">
                            <div className="col-5 m-auto">
                                <TextInput
                                    label="First name:"
                                    name="first_name"
                                    value={user.first_name}
                                    onChange={(e) => handleChange(e)}
                                    error={errors.first_name}
                                />
                            </div>

                            <div className="col-5 m-auto">
                                <TextInput
                                    label="Last name:"
                                    name="last_name"
                                    value={user.last_name}
                                    onChange={(e) => handleChange(e)}
                                    error={errors.last_name}
                                />
                            </div>
                        </div>

                        <div className="mb-4 col-11 m-auto">
                            <TextInput
                                label="Email address:"
                                name="email_address"
                                value={user.email_address}
                                onChange={(e) => handleChange(e)}
                                error={errors.email_address}
                            />
                        </div>

                        {!props.user.id &&
                            <div className="d-flex w-100 mb-4">
                                <div className="col-5 m-auto">
                                    <TextInput
                                        label="Password:"
                                        name="password"
                                        value={user.password}
                                        onChange={(e) => handleChange(e)}
                                        error={errors.password}
                                    />
                                </div>

                                <div className="col-5 m-auto">
                                    <TextInput
                                        label="Confirm password:"
                                        name="confirm_password"
                                        value={user.confirm_password}
                                        onChange={(e) => handleChange(e)}
                                        error={errors.confirm_password}
                                    />
                                </div>
                            </div>
                        }

                        <div className="col-11 m-auto">
                            <label className="lead form-label fs-16 p-0 m-0 mb-1">{getStatusColourById(user.is_active)} - Status:</label>
                            <select name="is_active" value={user.is_active} onChange={(e) => handleChange(e)} className={`form-control`} required={true}>
                                {statuses.map((status, index) =>
                                    <option key={index} value={status.id} defaultValue={user.status_id}>{status.name}</option>
                                )}
                            </select>
                        </div>

                        <div className="text-center lead mt-6 text-danger">
                            {errors.message}
                        </div>
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => validateForm()}>
                            {props.user.id ? 'Update' : 'Create'}
                        </button>
                        <button className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                onClick={() => props.onClose()}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserCRUD;