import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {DateTime} from "luxon";

import BuildingCrud from "../modals/BuildingCRUD";

import {setModal} from "../../utils/slices/modal.slice";
import {getStatusById} from "../../utils/helpers/status.helper";
import {postUnauthenticated} from "../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import UserCRUD from "../modals/UserCRUD";

const Users = () => {
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getAllUsers();
    }, []);

    function getAllUsers() {
        let url = API_ENDPOINT + "v1/Users/getAllUsers";
        let params = {};

        postUnauthenticated(url, params).then(results => {
            setUsers(results.data);
            setInit(true);
        })
    }

    function launchUserCRUD(user) {
        let modalSettings = {
            config: {
                component: UserCRUD,
                props: {
                    user: user
                }
            },
            onClose: () => {getAllUsers()}
        }
        dispatch(setModal(modalSettings));
    }

    return (
        init &&
        <div className="rounded-3">
            <div className="d-flex pb-2">
                <h2 className="col-9 lead fs-18 fw-semibold">Users</h2>
                <div className="col-3 text-end">
                    <button className="btn btn-primary w-40" onClick={() => launchUserCRUD({})}>
                        <i className="bi bi-person-fill-add fs-14" /> Create
                    </button>
                </div>
            </div>

            <div className="text-secondary fs-12 mb-1">Results: {users.length}</div>

            <div className="bg-white">
                <table className="table table-borderless">
                    <thead className="">
                        <tr className="lead fs-14 border border-1 border-start-0 border-end-0 rounded py-2">
                            <th className="py-3 px-4">#</th>
                            <th className="py-3 px-4">Full name</th>
                            <th className="py-3 px-4">Email address</th>
                            <th className="py-3 px-4">Status</th>
                            <th className="py-3 px-4">Created</th>
                            <th className="py-3 px-4" />
                        </tr>
                    </thead>

                    <tbody>
                        {users.map((user, index) =>
                            <tr className="lead fs-14 border border-start-0 border-end-0 border-light rounded">
                                <td className="py-3 px-4">{user.id}</td>
                                <td className="py-3 px-4">{user.first_name} {user.last_name}</td>
                                <td className="py-3 px-4">{user.email_address}</td>
                                <td className="py-3 px-4">{getStatusById(user.is_active)}</td>
                                <td className="py-3 px-4">{DateTime.fromSQL(user.created).toRelative({ unit: "" })} - {DateTime.fromSQL(user.created).toFormat('dd/MM/yyyy hh:mm a')}</td>
                                <td className="py-3 px-4">
                                    <span role="button" className="link-primary" onClick={() => launchUserCRUD(user)}>Edit</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Users;