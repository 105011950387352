export function getStatusById(id) {
    if(id == 1) {
        return <span className="bg-green-100 text-green-600 rounded p-1">Active</span>;
    }else if(id == 2) {
        return <span className="bg-yellow-100 text-yellow-600 rounded p-1">Pending</span>
    }else if(id == 3) {
        return <span className="bg-orange-100 text-orange-600 rounded p-1">Archived</span>
    }else if(id == 4) {
        return <span className="bg-red-100 text-red-600 rounded p-1">Deleted</span>
    }
}

export function getStatusColourById(status_id) {
    if(status_id == 1) {
        return <span className="fa fa-circle text-success" />
    }else if(status_id == 2) {
        return <span className="fa fa-circle text-warning" />
    }else if(status_id == 3) {
        return <span className="fa fa-circle text-secondary" />
    }else if(status_id == 4) {
        return <span className="fa fa-circle text-danger" />
    }
}